<template>
    <div>
  
      <b-nav-item-dropdown
      class="dropdown-notification mr-25 margin_icon"
      menu-class="dropdown-menu-media"
      right
  
      v-if="!isMobile"
     
    >
      <template #button-content>
  
        <b-link class="icon_dashboard"   @click="openSolicitudes"> 
  
          <b-avatar
          size="35"
        
          :badge="countSolicitudes"
          badge-classes="bg-danger"
          variant="light-primary"
          style="cursor:pointer;">
  
          <span class="d-flex align-items-center">
     
  
            <feather-icon
        class="color_icon"
          icon="UsersIcon"
          size="18"
        />
          </span>
  
          </b-avatar>
  
          </b-link>
  
    
      </template>
 

      <solicitudes-content v-if="showSolicitudes"   :user-id="userId" :token-auth="tokenAuth" />
  
  
      
  
  
    </b-nav-item-dropdown>
  
    <b-link v-else class="icon_dashboard" :to="{ name: 'solicitudes'}"> 
  
        <b-avatar
        size="35"
  
        :badge="countSolicitudes"
        badge-classes="bg-danger"
        variant="light-primary"
        style="cursor:pointer;margin-right: 10px;">
  
        <span class="d-flex align-items-center">
  
  
          <feather-icon
        class="color_icon"
        icon="UsersIcon"
        size="18"
        />
        </span>
  
        </b-avatar>
  
        </b-link>
  
    </div>
    
  </template>
  
  <script>
  import {
    BNavItemDropdown, BBadge, BLink, BAvatar, BRow, BCol, 
  } from 'bootstrap-vue'
  import  SolicitudesContent  from './SolicitudesContent.vue'

  
  export default {
    components: {
      BNavItemDropdown,
      SolicitudesContent,
      BBadge,
      BLink,
      BAvatar,
      BRow, BCol,
    },
    props: ['userId','tokenAuth','isMobile'],
    data() {
      /* eslint-disable global-require */
  

  
      return {
        countSolicitudes:'0',
        showSolicitudes:false,
      }
    },created(){
      
    },
      mounted(){


  
        this.getCountSolicitudes();
     
    },  methods: {

      openSolicitudes(){

        this.showSolicitudes=true;

      },

    
 
      getCountSolicitudes(){
  
   
  this.$https.post('/locals/getCountSolicitudes/', { userId:  this.userId }).then(response => {
      
  
     
    if (response.data.code == 200) {
  
  
  
        this.countSolicitudes= String(response.data.count);

  
  
    } else {
  
        this.getCountSolicitudes();
    }
  }).catch(error => {
       this.getCountSolicitudes();
  })
  
  
  }
  
  
    
  }
  }
  </script>
  
  <style>
  
  </style>
  